<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo />
      </b-link>
      <!-- /Brand logo-->

     <!-- Left Text-->
      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5 login-left">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <h2
            style="
              color: #fff;
              text-align: center;
              line-height: 1.5;
              font-weight: 400;
              font-size: 22px;
            "
          >
            A SMS platform for Africa, where we guarantee high success rate and
            real-time delivery receipts. Increase your ROI with SMS and Whatsapp
            campaigns, OTP's, Notifications, SMS API and much more
          </h2>
        </div>
      </b-col>
      <!-- /Left Text-->

      <slot></slot>
      
    </b-row>
  </div>
</template>

<script>
  import Logo from "@/layouts/components/Logo.vue";

    import {
        BRow,
        BCol,
        BLink,
    } from "bootstrap-vue";
    export default {
        components: {
            Logo,
            BRow,
            BCol,
            BLink,
        },
    }
</script>
<style scoped lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
<style scoped>
    .login-left:after {
        position: absolute;
        /* background: url(../images/bg/bg.jpg); */
        background:rgb(0, 82, 255);
        background: -webkit-gradient(linear,left top,right top,from(rgba(0, 82, 255,.9)),to(rgba(0,0,0,.4)));
        background: linear-gradient(
        90deg,rgba(0, 82, 255,1) 0,rgba(0, 0, 0,.4));
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0052ff",endColorstr="#0052ff",GradientType=1);
        /* z-index: -2; */
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        z-index: -1;
    }
    .brand-logo{
        max-width:200px
    }
    .form-control {
        padding: 1.578rem 1rem;
    }
    .btn{
        padding: 1.046rem 1.1rem;
    }
</style>